import { useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from '../Contexts/ContextProvider'
import { axios_instance } from './baseUrl'

const BASE_URL = ''

axios.defaults.withCredentials = true

//verification
export const useTokenVerification = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const token = true
    console.log(token, 'in admin dash')
    if (!token) {
      // Token doesn't exist, navigate to the login page
      navigate('/adminlogin')
    }
  }, [navigate])
}

// login section
export const loginAdmin = async (email, password) => {
  try {
    const response = await axios_instance.post(`/auth/login `, email, password)
    localStorage.setItem('id', response.data.otherDetails._id || null)
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

//  create branch
export const createBranch = async (payload) => {
  try {
    const response = await axios_instance.post('/branch', payload)
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}
//  update branch
export const updateBranch = async (payload, id) => {
  try {
    const response = await axios_instance.put(`/branch/${id}`, payload)
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}
// view all branchs
export const getAllBranches = async () => {
  try {
    const response = await axios_instance.get('/branch')
    return response.data.result
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}
// delete branch
export const deleteBranch = async (id) => {
  try {
    const response = await axios_instance.delete(`/branch/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

//get barnch by id

//branch api end

export const viewbranch = async () => {
  try {
    const response = await axios_instance.get('/branch', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

// ------------------------

//studennt

// export const addStudent = async (studentData) => {
//   try {
//     const response = await axios.post(
//       "/student/register",
//       studentData,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       }
//     );
//     console.log(response.authorization);
//     // console.log(response);
//     return response.data;
//   } catch (error) {
//         throw new Error(error.response.data.message)

//   }
// };
//student get
export const addStudent = async (studentData) => {
  try {
    const response = await axios_instance.post(`/student/register`, studentData)
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

//student get
export const getStudent = async (status = true) => {
  try {
    const response = await axios_instance.get(
      `/student?status=${status ? true : false}`
    )
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

export const getStudentbyid = async (id) => {
  try {
    const response = await axios_instance.get(
      `/student/${id}`,

      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    console.log(response)
    // console.log(response);
    return response.data
  } catch (error) {
    throw new Error(error.message)
  }
}

//update student

export const updateStudentById = async (studentId, updatedStudentData) => {
  try {
    const response = await axios_instance.put(
      `/student/${studentId}`,
      updatedStudentData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    return response.data
  } catch (error) {
    throw new Error('Failed to update student: ' + error.message)
  }
}

export const Addnotification = async (data) => {
  try {
    console.log(data, 'kjiuhgy')
    const response = await axios_instance.post(`/notification`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data
  } catch (error) {
    throw new Error('Failed to update student: ' + error.message)
  }
}

export const getnotification = async () => {
  try {
    const response = await axios_instance.get(`notification`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data
  } catch (error) {
    throw new Error('Failed to update student: ' + error.message)
  }
}

export const deletenotification = async (id) => {
  try {
    const response = await axios_instance.delete(`/notification/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data
  } catch (error) {
    throw new Error('Failed to update student: ' + error.message)
  }
}

export const deleteStudent = async (id) => {
  try {
    const response = await axios_instance.delete(`/student/${id}`)
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}
export const deleteStudentById = async (studentId) => {
  try {
    const response = await axios_instance.delete(`/student/${studentId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data
  } catch (error) {
    throw new Error('Failed to delete student: ' + error.message)
  }
}

//----------------------counts
export const getCountOfSubCourse = async () => {
  try {
    const response = await axios_instance.get('/counts/subcoursecount', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    console.log('response', response)
    // console.log(response);
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

//----------------------counts
export const getCountOfstudent = async () => {
  try {
    const response = await axios_instance.get('/counts/studentcount', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    console.log('response', response)
    // console.log(response);
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

//----------------------counts
export const getCountOftrainer = async () => {
  try {
    const response = await axios_instance.get('/counts/trainercount', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    console.log('response', response)
    // console.log(response);
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

//student count by course
export const studentCountbyCourse = async () => {
  try {
    const response = await axios_instance.get('/counts/piecoursecount', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    console.log('response', response)
    // console.log(response);
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

// ```````````````````````````````````````````````````````````````````````````````

//Course
// ```````````````````````````````````````````````````````````````````````````````
export const getCourse = async (ismaincourse = true) => {
  // getAllCourses
  try {
    const response = await axios_instance.get(
      `/course?ismaincourse=${ismaincourse ? true : false}`
    )
    return response.data
  } catch (error) {
    throw new Error(error.message || error.response.data.message)
  }
}
export const getSubcourse = async () => {
  // getAllCourses
  try {
    const response = await axios_instance.get('/course?ismaincourse=false')
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

//add course
export const addcourse = async (newCourse) => {
  try {
    const response = await axios_instance.post('/course', newCourse)
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}
//add course
export const addSubcourse = async (course) => {
  try {
    const response = await axios_instance.post(
      `/subcourse/${course.courseRef}`,
      course
    )

    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}
export const updateCourseAndSubCourse = async (payload, id) => {
  try {
    const response = await axios_instance.put(`/course/${id}`, payload)
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}
//delete
export const deleteSubcourse = async (id) => {
  try {
    const response = await axios_instance.delete(`/subcourse/${id}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })

    return response.data
  } catch (error) {
    throw new Error('Failed to delete course: ' + error.response.data.message)
  }
}

// Function to update a course by ID
export const updateCourse = async (courseId, courseData) => {
  try {
    const response = await axios_instance.put(
      `/course/${courseId}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    return response.data
  } catch (error) {
    throw new Error('Failed to update course: ' + error.message)
  }
}

// Function to delete a course by ID
export const deleteCourse = async (courseId) => {
  try {
    const response = await axios_instance.delete(`/course/${courseId}`)
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message || error.message)
  }
}

//subcourse get

export const getSubCourse = async () => {
  try {
    const response = await axios.get('/course?ismaincourse=false', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    console.log('response', response)
    // console.log(response);
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

//course by trainer

export const getcoursebytrainer = async (trid) => {
  try {
    const response = await axios_instance.get(
      `/course/coursebytrainer/${trid}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    console.log('response', response)
    // console.log(response);
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

//
export const getgraphdata = async (year) => {
  try {
    const response = await axios_instance.get(
      `/reports/yearwise?year=${year}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    console.log('response', response)
    // console.log(response);
    console.log(localStorage.getItem('token'), '--------------')
    return response.data
  } catch (error) {
    console.log('errr')
    console.log('errr', error)
    throw new Error(error.response.data.message)
  }
}

//add a Trainer
export const addTrainer = async (trainerData) => {
  // getAllCourses
  try {
    const response = await axios_instance.post(
      '/trainer/register/',
      trainerData
    )
    return response.data.result
  } catch (error) {
    throw new Error(error.response.data.message || error.message)
  }
}

//uploading picture
export const uploadPicture = async (pictureData) => {
  try {
    const response = await axios_instance.post('/upload', pictureData)
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message || error.message)
  }
}
export const limageViewLink = '/images/'

// /images/profilePic-1707450315663-113851525colorfu-mesh-gradient.jpg

// export const addTrainer = async (trainerData) => {
//   try {
//     const response = await axios.post(
//       `/trainer/register/`,
//       trainerData,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       }
//     );
//     return response.data;
//     console.log(response.data);
//   } catch (error) {
//         throw new Error(error.response.data.message);

//   }
// };

export const trainerdetailupdate = async (id, data) => {
  console.log(localStorage.getItem('token'), 'ghjks')
  try {
    const response = await axios_instance.put(`/trainer/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Use the localStorage.getItem("token") for authorization
      },
    })
    console.log('response', response)
    // console.log(response);
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

//trainer delete

export const trainerdetaildelete = async (id) => {
  try {
    const response = await axios_instance.delete(`/trainer/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Use the localStorage.getItem("token") for authorization
      },
    })
    console.log('response', response)
    // console.log(response);
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

export const getAllTrainers = async () => {
  try {
    const response = await axios_instance.get('/trainer', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data.result
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

export const getAllTrainersByBranchId = async (id) => {
  try {
    const response = await axios_instance.get(`/trainer/byBbranch/${id} `)
    // localStorage.setItem("id", response.data.otherDetails._id || null);
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

export const getAllSubcoursesByTrainerId = async (id) => {
  try {
    const response = await axios_instance.get(
      `/subcourse/getcourse/trainerid/${id} `
    )
    // localStorage.setItem("id", response.data.otherDetails._id || null);
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

// export const updateTrainer = async () => {
//   try {
//     const response = await axios.get(`/trainer`, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     });
//     console.log("response", response);
//     // console.log(response);
//     return response.data;
//   } catch (error) {
//         throw new Error(error.response.data.message)

//   }
// };
export const updateTrainer = async (data, id) => {
  try {
    const response = await axios_instance.put(`/trainer/${id} `, data)
    // localStorage.setItem("id", response.data.otherDetails._id || null);
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

export const updateStudent = async (data, id) => {
  try {
    const response = await axios_instance.put(`/student/${id} `, data)
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

//Branch

export const addbranch = async (branch) => {
  try {
    const response = await axios_instance.post('/branch', branch, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

//view all branches
export const getBranch = async (pageNo) => {
  try {
    const response = await axios_instance.get(`/branch?page=${pageNo}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

//activity get
export const getActivitybyadmin = async (id) => {
  try {
    const response = await axios_instance.get(`/activity?stid=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

//evaluate

export const evaluateanswer = async (data) => {
  try {
    const response = await axios_instance.post(`/activity/evaluate`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

//attendence count

export const AttendenceByCountid = async (id) => {
  console.log(id, 'oie')
  try {
    const response = await axios_instance.get(`/attendance/admincounts/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Use the localStorage.getItem("token") for authorization
      },
    })
    return response.data
  } catch (error) {
    console.error('Error in studentbyid:', error)
    throw new Error('Failed to fetch student: ' + error.response.data.message)
  }
}

export const AttendenceByCountfilterAdmin = async (stdate, enddate, id) => {
  try {
    const response = await axios_instance.get(
      `/attendance/admincounts/${id}?startDate=${stdate}&endDate=${enddate}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Use the localStorage.getItem("token") for authorization
        },
      }
    )
    return response.data
  } catch (error) {
    console.error('Error in studentbyid:', error)
    throw new Error('Failed to fetch student: ' + error.response.data.message)
  }
}

//reports
export const getreports = async () => {
  try {
    const response = await axios_instance.get(`/reports`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data
  } catch (error) {
    throw new Error('Failed to update student: ' + error.message)
  }
}

export const getStudentFilterReports = async (stdate, enddate) => {
  try {
    const response = await axios_instance.get(
      `/reports?startDate=${stdate}&endDate=${enddate}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    return response.data
  } catch (error) {
    throw new Error('Failed to update student: ' + error.message)
  }
}

export const getcoursereports = async () => {
  try {
    const response = await axios_instance.get(`/reports/course`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data
  } catch (error) {
    throw new Error('Failed to update student: ' + error.message)
  }
}

export const addrequest = async (data) => {
  try {
    const response = await axios_instance.post(`/request`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data
  } catch (error) {
    throw new Error('Failed to update student: ' + error.message)
  }
}

export const viewrequestadmin = async () => {
  try {
    const response = await axios_instance.get(`/request`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data
  } catch (error) {
    throw new Error('Failed to update student: ' + error.message)
  }
}

export const verifyAccessToken = async () => {
  let response = await axios.get(
    'https://api.thecqube.com/api/auth/verifyCookie'
  )
  return response
}
export const logout = async () => {
  let response = await axios.post('https://api.thecqube.com/api/auth/logout')
  return response
}
