import React, { Suspense, useState } from 'react'
import { useEffect } from 'react'

import { getAllTrainers, trainerdetaildelete } from '../service/apiService'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
} from '@material-ui/core'
import { AiFillDelete, AiFillEdit } from 'react-icons/ai'
import { errorToastify, successToastify } from '../Components/Student/toastify'
import Loader from '../Components/Loader'

import PlaceHolder from '../image/placeholderImages/placeholder-image.png'

const AddTrainer = React.lazy(() => import('./Trainer/AddTrainer'))
const EditTrainer = React.lazy(() => import('./Trainer/EditTrainer'))

const Trainers = () => {
  const [updateForm, setUpdateForm] = useState({ id: '' })

  const [loading, setLoading] = useState(false)
  // edit

  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage] = useState(10)

  const [refresh, setrefresh] = useState(false)
  //

  const [trainerdata, settrainerdata] = useState([])

  //
  const [state, setState] = useState({
    toggleAddButton: false,
    toggleEditButton: false,
    toggleListButton: true,
  })

  const ListCourses = () => {
    setState({
      toggleListButton: true,
      toggleAddButton: false,
      toggleEditButton: false,
    })
  }
  const AddCourses = () => {
    setState({
      toggleAddButton: true,
      toggleListButton: false,
      toggleEditButton: false,
    })
  }

  const tableHeaders = [
    'Profile Pic',
    'Name',
    'Email',
    'Phone',
    'Branch',
    'Course',
    'Linkedin',
    'Github',
    'Joined Date',
    'Edit',
    'Delete',
  ]
  const indexOfLastRow = currentPage * rowsPerPage
  const indexOfFirstRow = indexOfLastRow - rowsPerPage
  const currentData = trainerdata.slice(indexOfFirstRow, indexOfLastRow)
  const totalPages = Math.ceil(trainerdata.length / rowsPerPage)

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage)
    }
  }

  const handleEdit = (id) => {
    setUpdateForm({ id: id })
    setState({
      toggleEditButton: true,
      toggleAddButton: false,
      toggleListButton: false,
    })
  }

  useEffect(() => {
    fetchTrainers()
  }, [refresh, state])

  const fetchTrainers = async () => {
    try {
      const trainersdata = await getAllTrainers()
      settrainerdata(trainersdata)
    } catch (err) {}
  }

  useEffect(() => {
    setLoading(true)

    setTimeout(() => {
      setLoading(false)
    }, 500)
    fetchTrainers()
  }, [])

  const handleDelete = async (id) => {
    try {
      let answer = window.confirm('Are you sure?')
      if (!answer) return true
      await trainerdetaildelete(id)
      successToastify('Deleted Successfully')
      setrefresh(!refresh)
    } catch (error) {
      setrefresh(!refresh)
      errorToastify(error?.message)
    }
  }

  return (
    <div className="container mx-auto px-10 bg-white rounded-3xl">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="mb-8">
            <div className=" flex flex-col justify-between">
              <h1 className="text-2xl font-bold mb-4">Trainers Management</h1>
              <div className="flex gap-3 mb-5">
                <button
                  type="button"
                  onClick={ListCourses}
                  className="bg-slate-400 text-white px-4 py-1 rounded hover:bg-slate-500">
                  {' '}
                  List Trainer{' '}
                </button>
                <button
                  type="button"
                  onClick={AddCourses}
                  className="bg-orange-400 text-white px-4 py-1 rounded hover:bg-orange-500">
                  {' '}
                  Add Trainer{' '}
                </button>
              </div>
            </div>

            {state.toggleAddButton && (
              <Suspense fallback={<p>Loading...</p>}>
                <AddTrainer />
              </Suspense>
            )}

            {state.toggleEditButton && (
              <Suspense fallback={<p>Loading...</p>}>
                <EditTrainer editFormProp={updateForm} />
              </Suspense>
            )}

            {state.toggleListButton &&
              (currentData.length === 0 ? (
                'No data found'
              ) : (
                <TableContainer
                  style={{ maxWidth: '920px', overflow: 'scroll' }}
                  component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow className="h-fit">
                        {tableHeaders?.map((header, index) => (
                          <TableCell
                            key={index}
                            style={{
                              backgroundColor: '#475569',
                              fontSize: '17px',
                              color: 'white',
                            }}>
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody className="text-lg">
                      {currentData.map((trainer) => (
                        <TableRow key={trainer._id}>
                          <TableCell>
                            <img
                              src={trainer.profilePic || PlaceHolder}
                              alt="preview"
                              className="w-11 object-contain h-10 rounded-full"
                            />
                          </TableCell>
                          <TableCell>{trainer.name}</TableCell>
                          <TableCell>{trainer.email}</TableCell>
                          <TableCell>{trainer.phoneNumber}</TableCell>
                          <TableCell>{trainer.branchName}</TableCell>
                          <TableCell>
                            <div className="">
                              {trainer.course
                                ? trainer.course.map((item) => (
                                    <p className="text-xs">{item.name}</p>
                                  ))
                                : '-'}
                            </div>
                          </TableCell>
                          <TableCell>{trainer.linkedin}</TableCell>
                          <TableCell>{trainer.github}</TableCell>
                          <TableCell>
                            {new Date(trainer.joinedDate).toLocaleDateString(
                              'en-GB'
                            )}
                          </TableCell>

                          <TableCell>
                            <IconButton
                              size="small"
                              title="edit"
                              onClick={() => handleEdit(trainer._id)}>
                              <AiFillEdit size={25} />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              size="small"
                              title="Delete"
                              onClick={() => handleDelete(trainer._id)}>
                              <AiFillDelete size={25} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ))}
            {state.toggleListButton &&
              (currentData.length === 0 ? (
                ''
              ) : (
                <div className="pagination-container text-black">
                  <Button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}>
                    Previous
                  </Button>
                  <span className="page-number">
                    Page {currentPage} of {totalPages}
                  </span>
                  <Button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}>
                    Next
                  </Button>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  )
}

export default Trainers
