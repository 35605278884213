import React, { useEffect, useState } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  TextField,
} from '@material-ui/core'
import { AiFillDelete, AiFillEdit } from 'react-icons/ai'
import { errorToastify, successToastify } from '../Components/Student/toastify'
import { deleteCourse, getCourse } from '../service/apiService'
import Loader from '../Components/Loader'

const AddCourse = React.lazy(() => import('./Courses/AddCourse'))
const EditCourse = React.lazy(() => import('./Courses/EditCourse'))

const Courses = () => {
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage)
    }
  }

  // loader
  const [loader, setLoader] = useState(false)
  // --------------
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage] = useState(5)
  const [refresh, setRefresh] = useState(false)
  const [courses, setCourses] = useState([])
  const tableHeaders = ['Name', 'Created date', 'Description', 'Edit', 'Delete']
  const indexOfLastRow = currentPage * rowsPerPage
  const indexOfFirstRow = indexOfLastRow - rowsPerPage
  const currentData = courses.slice(indexOfFirstRow, indexOfLastRow)
  const totalPages = Math.ceil(courses.length / rowsPerPage)

  const [searchQuery, setSearchQuery] = useState('')

  const [updateForm, setUpdateForm] = useState({ id: '' })

  const [state, setState] = useState({
    toggleAddButton: false,
    toggleEditButton: false,
    toggleListButton: true,
  })

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase()
    setSearchQuery(query)
  }
  // courses
  const ListCourses = () => {
    setState({
      toggleListButton: true,
      toggleAddButton: false,
      toggleEditButton: false,
    })
  }
  const AddCourses = () => {
    setState({
      toggleAddButton: true,
      toggleListButton: false,
      toggleEditButton: false,
    })
  }

  // delete course data
  const handledelete = async (id) => {
    let answer = window.confirm('Are you sure?')
    if (!answer) return true
    try {
      const response = await deleteCourse(id)
      setRefresh(!refresh)
      successToastify(response.message)
    } catch (error) {
      console.log(error, 'errrr')
      errorToastify(error?.message || error.response.data.message)
    }
  }
  //  -------------------

  // Function to fetch courses and update the state
  const fetchCourses = async () => {
    try {
      const coursesData = await getCourse()
      setCourses(coursesData.result)
    } catch (error) {
      setCourses([])
    }
  }

  const handleEdit = (id) => {
    let filterData = courses.find((item) => item._id == id)
    setUpdateForm(filterData)
    setState({
      toggleEditButton: true,
      toggleAddButton: false,
      toggleListButton: false,
    })
  }

  useEffect(() => {
    setLoader(true)
    setTimeout(() => {
      setLoader(false)
    }, 500)
    fetchCourses()
  }, [])

  useEffect(() => {
    fetchCourses()
  }, [refresh, state])

  const UiForViewData = currentData
    .filter((item) => {
      return searchQuery === ''
        ? item
        : item.name.toLowerCase().includes(searchQuery)
    })
    .map((student) => (
      <TableRow key={student._id}>
        <TableCell>{student.name}</TableCell>
        <TableCell>
          {new Date(student.createdAt).toLocaleDateString('en-GB')}
        </TableCell>
        <TableCell>{student.description || '-'}</TableCell>

        <TableCell>
          <IconButton
            size="small"
            title="Edit"
            onClick={() => handleEdit(student._id)}>
            <AiFillEdit size={25} />
          </IconButton>
        </TableCell>

        <TableCell>
          <IconButton
            size="small"
            title="Delete"
            onClick={() => handledelete(student._id)}>
            <AiFillDelete size={25} />
          </IconButton>
        </TableCell>
      </TableRow>
    ))

  return (
    <div className="container mx-auto p-10 bg-white rounded-3xl">
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="mb-8">
            <h1 className="text-2xl font-bold mb-4">Course Management</h1>
            <div className="flex gap-3 mb-5">
              <button
                type="button"
                onClick={ListCourses}
                className="bg-slate-400 text-white px-4 py-1 rounded hover:bg-slate-500">
                {' '}
                List Courses{' '}
              </button>
              <button
                type="button"
                onClick={AddCourses}
                className="bg-orange-400 text-white px-4 py-1 rounded hover:bg-orange-500">
                {' '}
                Add Course{' '}
              </button>
            </div>
            {state.toggleAddButton && (
              <React.Suspense fallback={<p>loading...</p>}>
                <AddCourse />
              </React.Suspense>
            )}

            {state.toggleListButton &&
              (currentData.length === 0 ? (
                'No data found'
              ) : (
                <>
                  <div className="mb-2 mt-3 gap-6 flex items-center">
                    <TextField
                      label="Search by Name"
                      variant="outlined"
                      height="30px"
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                  </div>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow className="h-fit">
                          {tableHeaders?.map((header, index) => (
                            <TableCell
                              key={index}
                              style={{
                                backgroundColor: '#475569',
                                fontSize: '17px',
                                color: 'white',
                              }}>
                              {header}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody className="text-lg">{UiForViewData}</TableBody>
                    </Table>
                  </TableContainer>
                </>
              ))}

            {state.toggleEditButton && (
              <React.Suspense fallback={<p>loading...</p>}>
                <EditCourse editFormProp={updateForm} />
              </React.Suspense>
            )}

            {state.toggleListButton &&
              (currentData.length === 0 ? (
                ''
              ) : (
                <div className="pagination-container text-black">
                  <Button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}>
                    Previous
                  </Button>
                  <span className="page-number">
                    Page {currentPage} of {totalPages}
                  </span>
                  <Button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}>
                    Next
                  </Button>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  )
}

export default Courses
